<template>
  <v-row class="pa-3 text-center body-2" justify="center" align="center">
    <v-col :cols="12" class="display-1">
      About
    </v-col>
    <v-col :cols="12">
      <span class="font-weight-medium">
        Music Guru was created by Musitelligence - A company dedicated to creating the best free tools for
        musicians.<br>For more information click
        <span small text @click="openMusitelligence" style="border-bottom: solid 1px;cursor: pointer">
          Here
        </span>
      </span>
    </v-col>
    <v-col :cols="12">
      This app is a PWA - Progressive Web App.<br><br>
      What does it mean...?<br>
      That you can install it on every device and platform, access it offline and use it as a
      native app even on Android and IOS!
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col>
      <span class="title">Installation Instructions</span>
    </v-col>
    <v-col :cols="12">
      <install-instructions/>
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col :cols="12">
      <span class="title">The App</span><br><br>
      This app allows you to find tons of different scales and chords, see what they sound like in
      different instruments and octaves, check out chord progressions and compose your own using the
      scales that you find easily!

      For help press the options icon in the top right -> Help.
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col :cols="12">
      <span class="title">Credits<br></span>
      <span class="font-weight-medium">
        <a href="https://vuejs.org/" target="_blank" rel="noreferrer noopener">Vue JS</a>
        , the best progressive javascript framework.<br>
        <a href="https://vuetifyjs.com/en/" target="_blank" rel="noreferrer noopener">Vuetify</a>
        , the best material design component framework.<br>
        <a href="https://tonejs.github.io/" target="_blank" rel="noreferrer noopener">Tone JS</a>
        , the best framework for creating music in the browser.<br>
        <a href="https://bento-starter.netlify.com/" target="_blank" rel="noreferrer noopener">Bento-Starter</a>,
        The awesome template i used for the project.
      </span>
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col :cols="12">
      <v-btn rounded color="secondary" @click="aboutMeClick">
        About Me
      </v-btn>
      <v-dialog v-model="aboutMeDialog" scrollable max-width="1000">
        <app-dialog @close="aboutMeDialog=false">
          <template #title>About Me</template>
          <v-card-text class="pa-4 font-weight-medium">
            <p>
              Hi!<br>I'm Sean, a programmer and a Musician.<br><br>
              <strong>
                I have a
                dream
              </strong>&nbsp;-
              creating the ultimate set of tools for musicians, and make it availble to everyone
              for
              free!<br><br>In order to do that, i created&nbsp;
              <a href="https://github.com/Seanitzel/note-art" target="_blank" rel="noreferrer noopener">
                Note-Art
              </a>,
              a open source library that implements music theory in code.<br><br>After publishing
              Music Guru 1.0, i got TONS of suggestions for improvements and additions, and added
              a
              lot of them to the 2nd version.<br>&nbsp;<br>Im a Software engineering student(in
              my
              4th year), i also have a job, and a gf, so its really hard to manage between
              everything...<br><br>I already have a list of awesome ideas for people that are
              into
              music, and creating these is literally my passion.<br><br>some of them -&nbsp;<br>1.
              Improve Music Guru and release it for the app store.<br>2. Create a tool that will
              be
              able to recognize, classify and record notes played by any instrument and arrange
              them(already a work in progress).<br>3. Create the ultimate tool for developing
              relative
              pitch.<br>4. Creating tools for singers.<br><br>All of these will be a part of
              'Musitelligence', a combination of music and intelligence ;)<br><br>If you wanna
              help bring this idea to life ...
            </p>
            <a href="https://www.patreon.com/Seanitzel" target="_blank" rel="noreferrer noopener"
               class="text--secondary">
              <v-btn color="success">
                Support Me 🤗
              </v-btn>
            </a>
          </v-card-text>
        </app-dialog>
      </v-dialog>
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col :cols="12" class="title">
      Contributors
    </v-col>
    <v-col :cols="12" v-for="(c, i) in contributors" :key="c" class="subtitle-1 font-weight-medium pa-0">
      {{ i+1 }}. {{ c }}
    </v-col>
    <v-col :cols="12">
      <v-divider class="secondary"/>
    </v-col>
    <v-col :cols="12">
      <span class="display-1">Contact Us</span>
    </v-col>
    <v-col class="font-weight-medium subtitle-1">
      <v-row>
        <v-col align="start">
          <v-icon v-text="'$vuetify.icons.email'" color="blue"/>
        </v-col>
        <v-col>
          <span class="select">
            contact-us@musitelligence.com
          </span>
        </v-col>
        <v-col/>
      </v-row>
      <v-row>
        <v-col align="start">
          <v-icon v-text="'$vuetify.icons.place'" color="blue"/>
        </v-col>
        <v-col>
          <span class="select">
            Ramat Gan, Israel
          </span>
        </v-col>
        <v-col/>
      </v-row>
    </v-col>
    <v-row class="purple darken-4">
      <v-col class="white--text">
        Made with
        <app-icon icon="favorite" color="red"/>
        for Musicians!
      </v-col>
    </v-row>
  </v-row>
</template>

<script>
  // import SegmentIO from 'reporting/SegmentIO'

  export default {
    name: 'About',

    components: {
      AppDialog:           () => import(/* webpackChunkName: "app-main" */ 'framework/ui/components/AppDialog'),
      InstallInstructions: () => import(/* webpackChunkName: "info" */ 'framework/ui/sections/InstallInstructions'),
    },

    data() {
      return {
        aboutMeDialog: false,
        contributors:  ['Andrew H', 'Brian Crockford', 'Tiago O', 'Merav Alon', 'Denis Zagorodny'],
      }
    },

    methods: {
      aboutMeClick() {
        // SegmentIO.aboutMeClicked()
        this.aboutMeDialog = true
      },
      openMusitelligence() {
        window.open('https://musitelligence.com/', '_blank')
      },
    },
  }
</script>

<style scoped>
  .select {
    -webkit-user-select: all; /* Chrome all / Safari all */
    -moz-user-select:    all; /* Firefox all */
    -ms-user-select:     all; /* IE 10+ */
    user-select:         all; /* Likely future */
  }

  a {
    color:         white;
    border-bottom: solid .5px;
  }
</style>
